import { Checkbox, IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import RedoIcon from '@material-ui/icons/Redo';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardHideIcon from '@material-ui/icons/KeyboardHide';
import SettingsIcon from '@material-ui/icons/Settings';
import InboxIcon from '@material-ui/icons/Inbox';
import PeopleIcon from '@material-ui/icons/People';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import React, { useEffect, useState } from 'react';
import Section from './Section';
import './EmailList.css';
import EmailRow from './EmailRow';
import { useHistory, useLocation } from 'react-router';
import { getAllEmailsApi } from './api';
import { useSelector, useDispatch } from 'react-redux';
import { logout, selectUser } from './features/userSlice';

function EmailList () {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector(selectUser);
    const location = useLocation();

    const [emails, setEmails] = useState([]);

    useEffect(() => {
        const pathname = location.pathname
        if (user) {
            getAllEmailsApi(user.token, pathname && pathname.includes('spam/'))
                .then(el => {
                    if (el && el.length)
                        setEmails(el)
                }).catch((error) => {
                    alert('Email Access Token not found')
                    dispatch(logout());
                    return history.push('/')
                })
        }
    }, []);

    return (
        <div className="emailList">
            <div className="emailList__settings">
                <div className="emailList__settingsLeft">
                    <Checkbox />
                    <IconButton>
                        <ArrowDropDownIcon />
                    </IconButton>
                    <IconButton>
                        <RedoIcon />
                    </IconButton>
                    <IconButton>
                        <MoreVertIcon />
                    </IconButton>
                </div>

                <div className="emailList__settingsRight">
                    <IconButton>
                        <ChevronLeftIcon />
                    </IconButton>
                    <IconButton>
                        <ChevronRightIcon />
                    </IconButton>
                    <IconButton>
                        <KeyboardHideIcon />
                    </IconButton>
                    <IconButton>
                        <SettingsIcon />
                    </IconButton>
                </div>
            </div>

            <div className="emailList__sections">
                <Section Icon={InboxIcon} title="Primary" color="red" selected />
                <Section Icon={PeopleIcon} title="Social" color="#1A73E8" />
                <Section Icon={LocalOfferIcon} title="Promotions" color="green" />
            </div>

            <div className="emailList__list">

                {emails.map(({ id, from, content, time }) => (
                    <EmailRow
                        user={user}
                        id={id}
                        key={id}
                        title={from}
                        subject={''}
                        description={content}
                        time={time}
                    />
                ))}

            </div>
        </div>
    )
}

export default EmailList;
