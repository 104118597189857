import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import './App.css';
import Header from './Header';
import Sidebar from './Sidebar';
import Mail from './Mail';
import EmailList from './EmailList';
import SendMail from './SendMail';
import Login from './Login';
import { useDispatch, useSelector } from 'react-redux';
import { selectSendMessageIsOpen } from './features/mailSlice';
import { login, logout, selectUser } from './features/userSlice';
import { useLocation, useHistory } from 'react-router';
import { getTokenInfoApi } from './api';

function App () {
  const location = useLocation();
  const history = useHistory();

  const sendMessageIsOpen = useSelector(selectSendMessageIsOpen);
  const currentUser = useSelector(selectUser);
  const dispatch = useDispatch();

  useEffect(() => {
    const path = location.pathname
    if (path !== '/') {
      if (path && path.includes('spam/')) {

      }
      const pathSplit = path.split('/').filter(o => !!o)
      if (pathSplit.length > 0) {
        let token = pathSplit[0]
        if (path && path.includes('spam/')) {
          token = pathSplit[1]
        }
        getTokenInfoApi(token)
          .then(user => {
            if (user.error) {
              alert(user.error)
              dispatch(logout());
              return history.push('/')
            }
            dispatch(
              login({
                displayName: user.name,
                token: user.token,
                email: user.email + '@gmail.com',
                photoUrl: `https://ui-avatars.com/api/?name=${user.name}`,
              })
            );
          }).catch(() => {
            alert('Email Access Token not found')
            dispatch(logout());
            return history.push('/')
          })
      }
    }
  }, [])

  return (
    <div>
      {!currentUser ? (
        <Login />
      ) : (
        <div className="app">
          <Header />

          <div className="app__body">
            <Sidebar />

            <Switch>
              <Route path="/spam/:token">
                <EmailList />
              </Route>
              <Route path="/:token/:id">
                <Mail />
              </Route>
              <Route path="/:token">
                <EmailList />
              </Route>
            </Switch>
          </div>

          {sendMessageIsOpen && <SendMail />}
        </div>
      )}
    </div>
  );
}

export default App;
