import { Button, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { login, logout } from './features/userSlice';
import { useHistory } from 'react-router';
import './Login.css';
import { getTokenInfoApi } from './api';

function Login () {

    const dispatch = useDispatch();
    const history = useHistory();
    const [token, setToken] = useState("");

    const signIn = () => {
        if (!token) {
            return alert('Token is required');
        }
        getTokenInfoApi(token)
            .then(user => {
                if (user.error) {
                    return alert(user.error)
                }
                dispatch(
                    login({
                        displayName: user.name,
                        token: user.token,
                        email: user.email + '@gmail.com',
                        photoUrl: `https://ui-avatars.com/api/?name=${user.name}`,
                    })
                );
                history.push("/" + user.token);
            }).catch(() => {
                alert('Email Access Token not found');
                dispatch(logout());
            })
    }
    return (
        <div className="login">
            <div className="login__container">
                <img
                    src="https://static.dezeen.com/uploads/2020/10/gmail-google-logo-rebrand-workspace-design_dezeen_2364_col_0.jpg"
                    alt=""
                />
                <TextField id="outlined-basic" label="Email Access Token" variant="outlined"
                    value={token}
                    onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                            signIn()
                        }
                    }}
                    onChange={(event) => {
                        setToken(event.target.value);
                    }} />
                <br />
                <Button variant="contained" color="primary" onClick={signIn} >Login</Button>
            </div>
        </div>
    )
}

export default Login;
