import axios from 'axios'
import { Promise } from 'bluebird'

export const isDev = () => !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

const baseUrl = isDev() ? 'http://localhost:3368/api' : '/api'

export const getTokenInfoApi = (token) => {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + `/mailboxes/login/${token}`, {})
      .then(function (response) {
        const data = response.data
        resolve(data)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const getAllEmailsApi = (token, isSpam) => {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + (isSpam ? `/mailboxes/spam/${token}` : `/mailboxes/${token}`), {})
      .then(function (response) {
        const data = response.data
        resolve(data)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const getDetailEmailApi = (token, id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + `/mailboxes/${token}/${id}`, {})
      .then(function (response) {
        const data = response.data
        resolve(data)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}