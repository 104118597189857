import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import ErrorIcon from '@material-ui/icons/Error';
import DeleteIcon from '@material-ui/icons/Delete';
import EmailIcon from '@material-ui/icons/Email';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import PrintIcon from '@material-ui/icons/Print';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import './Mail.css';
import { useHistory, useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { logout, selectUser } from './features/userSlice';
import { getDetailEmailApi } from './api';

function Mail () {

    const location = useLocation();
    const history = useHistory();
    const user = useSelector(selectUser);
    const dispatch = useDispatch();

    const [content, setContent] = useState(null);

    useEffect(() => {
        const path = location.pathname
        if (path !== '/') {
            const pathSplit = path.split('/').filter(o => !!o)
            if (pathSplit.length > 1) {
                const id = pathSplit[1]

                if (user) {
                    getDetailEmailApi(user.token, id)
                        .then(em => {
                            setContent(em)
                        }).catch(() => {
                            alert('Email Access Token not found')
                            dispatch(logout());
                            return history.push('/')
                        })
                }
            }
        }
    }, [])

    return (
        <div className="mail">
            <div className="mail__tools">
                <div className="mail__toolsLeft">
                    <IconButton onClick={() => history.push('/' + user.token)}>
                        <ArrowBackIcon />
                    </IconButton>

                    <IconButton>
                        <MoveToInboxIcon />
                    </IconButton>

                    <IconButton>
                        <ErrorIcon />
                    </IconButton>

                    <IconButton>
                        <DeleteIcon />
                    </IconButton>

                    <IconButton>
                        <EmailIcon />
                    </IconButton>

                    <IconButton>
                        <WatchLaterIcon />
                    </IconButton>

                    <IconButton>
                        <CheckCircleIcon />
                    </IconButton>

                    <IconButton>
                        <LabelImportantIcon />
                    </IconButton>

                    <IconButton>
                        <MoreVertIcon />
                    </IconButton>
                </div>

                <div className="mail__toolsRight">
                    <IconButton>
                        <UnfoldMoreIcon />
                    </IconButton>

                    <IconButton>
                        <PrintIcon />
                    </IconButton>

                    <IconButton>
                        <ExitToAppIcon />
                    </IconButton>
                </div>
            </div>
            {content ?
                <div className="mail__body">
                    <div className="mail__bodyHeader">
                        <h2>{content.subject}</h2>
                        <LabelImportantIcon className="mail__important" />
                        <p>{content.from.text}</p>
                        <p className="mail__time">{new Date(content.date).toUTCString()}</p>
                    </div>
                    <div className="mail__message" dangerouslySetInnerHTML={{ __html: content.html }} />
                </div> : <div />}
        </div>
    )
}

export default Mail;
